import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import { Nullable, Nullish } from '@tager/web-core';

import { colors } from '@/constants/theme';

import { ReactComponent as StarSvg } from '../assets/star.svg';

type Props = {
  dayOfMonth: number;
  dayCoefficients?: Array<{ date: string; coefficient: number }>;
  disabledDates?: Array<string | undefined>;
  date: Nullish<Date>;
  currentDay: Nullable<Date>;
  isHideRaisingCoefficient: boolean;
};

function CustomDay({
  dayOfMonth,
  dayCoefficients,
  currentDay,
  date,
  disabledDates,
  isHideRaisingCoefficient,
}: Props) {
  const t = useTranslations();

  function getCoefficient() {
    const coefficient = dayCoefficients?.find(
      (dayCoefficient) =>
        new Date(dayCoefficient.date).setHours(0) === date?.setHours(0)
    )?.coefficient;
    if (coefficient) {
      return Math.round((coefficient - 1) * 100);
    }
    return null;
  }

  const isDisabled =
    disabledDates?.some((disabledDate) =>
      disabledDate && date
        ? new Date(disabledDate).setHours(0) === date.setHours(0)
        : false
    ) || false;

  const coefficient = getCoefficient();
  const isRaising = coefficient && coefficient > 0;

  return (
    <Container
      isActiveDay={
        currentDay && date
          ? new Date(currentDay).setHours(0) === date.setHours(0)
          : false
      }
    >
      {!isDisabled && isRaising ? (
        <RaisingCoefficientStar title={t('order-form.rise_coefficient_hint')}>
          <StarSvg />
        </RaisingCoefficientStar>
      ) : null}

      {!isDisabled && coefficient ? (
        <Coefficient
          isHideRaisingCoefficient={isHideRaisingCoefficient}
          isRaising={Number(coefficient) > 0}
        >
          {coefficient}%
        </Coefficient>
      ) : null}
      {dayOfMonth}
    </Container>
  );
}

export default CustomDay;

const RaisingCoefficientStar = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 4px;
`;

const Container = styled.div<{ isActiveDay: boolean }>`
  padding: 11px;
  font-weight: 500;
  position: relative;
  padding-top: 25px;
  ${(props) =>
    props.isActiveDay
      ? css`
          color: #ffff;

          ${Coefficient} {
            color: #ffff;
          }
        `
      : null}
`;

const Coefficient = styled.span<{
  isRaising: boolean;
  isHideRaisingCoefficient: boolean;
}>`
  position: absolute;
  font-size: 10px;
  top: 7px;
  left: 53%;
  transform: translateX(-50%);
  font-weight: 600;
  color: #10e387;
  line-height: 0;

  ${(props) =>
    props.isRaising
      ? css`
          color: ${colors.red};
          display: ${props.isHideRaisingCoefficient ? 'none' : 'block'};
        `
      : null}
`;
