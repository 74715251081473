import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import { colors } from '@/constants/theme';

import { ReactComponent as PlusSvg } from '../svg/plus.svg';

type Props = {
  selected: boolean;
  onSelect: () => void;
};

const OrderFormUserAddressEmpty = ({ selected, onSelect }: Props) => {
  const t = useTranslations();

  return (
    <Component $selected={selected} onClick={() => !selected && onSelect()}>
      <Left>
        <Toggle>
          <PlusSvg />
        </Toggle>
      </Left>
      <Right>
        <EmptyText>{t('order-form.new_address')}</EmptyText>
      </Right>
    </Component>
  );
};

const Left = styled.div``;

const Right = styled.div`
  flex: 1 1 1px;
`;

const Toggle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid ${colors.grayDark};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease;
`;

const EmptyText = styled.span`
  font-size: 14px;
  line-height: 30px;
  font-weight: bold;
  color: ${colors.blackText};
`;

const Component = styled.div<{ $selected: boolean }>`
  border-radius: 5px;
  border: 2px solid ${colors.border};
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.3s all ease;

  &:hover {
    border-color: ${colors.grayDark};
  }

  ${(props) =>
    props.$selected &&
    css`
      border-color: ${colors.green} !important;

      ${Toggle} {
        border-color: ${colors.green};
        color: ${colors.green};
      }
    `}
`;

export default OrderFormUserAddressEmpty;
