import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import OrderFormSidebarBlock from '@/modules/Order/containers/OrderFormSidebar/components/OrderFormSidebarBlock';
import { media } from '@/utils/mixin';

import { ReactComponent as BadgeSvg } from '../svg/badge.svg';

const OrderFormSidebarBadge = () => {
  const t = useTranslations();

  return (
    <Component>
      <BadgeSvg />
      <Text>
        {t('order-form.sidebar_quality')}{' '}
        <b>{t('order-form.sidebar_quality_free')}</b>
      </Text>
    </Component>
  );
};

const Component = styled(OrderFormSidebarBlock)`
  display: flex;
  align-items: center;

  svg {
    min-width: 80px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: rgb(134, 143, 155);
  letter-spacing: -0.3px;
  font-weight: 500;
  margin-left: 25px;

  ${media.tabletSmall(css`
    font-size: 14px;
  `)}
  b {
    font-weight: 700;
    color: rgb(18, 207, 124);
    font-size: 120%;
  }
`;

export default OrderFormSidebarBadge;
