import React from 'react';
import Router from 'next/router';
import { useTranslations } from 'use-intl';

import OrderFormSubmit from '@/modules/Order/components/OrderFormSubmit';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectSelectedSubscription,
  selectSubscriptionSubmitLoading,
  setSubscriptionSubmitLoading,
} from '@/modules/Order/store/subscription';
import { setOrderSubscription } from '@/services/requests/orders';
import { selectOrderId, setErrorMessage } from '@/modules/Order/store/common';

const OrderFormThirdStepSubmit = () => {
  const dispatch = useTypedDispatch();
  const loading = useTypedSelector(selectSubscriptionSubmitLoading);
  const period = useTypedSelector(selectSelectedSubscription);
  const orderId = useTypedSelector(selectOrderId);

  const t = useTranslations();

  const onSubmit = async () => {
    if (!orderId || !period) return;

    dispatch(setSubscriptionSubmitLoading(true));
    try {
      await setOrderSubscription(orderId, period?.id);
      await Router.replace('/profile/orders');
    } catch (e) {
      dispatch(setErrorMessage(t('order-form.subscription_create_error')));
    } finally {
      dispatch(setSubscriptionSubmitLoading(false));
    }
  };

  const onSkip = async () => {
    dispatch(setSubscriptionSubmitLoading(true));
    await Router.replace('/profile/orders');
    dispatch(setSubscriptionSubmitLoading(false));
  };

  if (!period) {
    return (
      <OrderFormSubmit
        isContainer={true}
        submitLabel={t('order-form.skip')}
        onSubmit={onSkip}
        submitLoading={loading}
        mobileNoFixed={true}
      />
    );
  } else {
    return (
      <OrderFormSubmit
        isContainer={true}
        submitLabel={t('order-form.subscription_submit')}
        onSkip={loading ? undefined : onSkip}
        onSubmit={onSubmit}
        submitLoading={loading}
      />
    );
  }
};

export default OrderFormThirdStepSubmit;
