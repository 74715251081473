import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import TextInput from '@/components/TextInput';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  personalActions,
  selectOrderEmail,
  selectOrderName,
} from '@/modules/Order/store/personal';
import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';
import { media } from '@/utils/mixin';

const OrderFormPersonal: React.FC = () => {
  const submitLoading = useTypedSelector(selectOrderSubmitLoading);

  const dispatch = useTypedDispatch();

  const { setName, setEmail } = personalActions;

  const name = useTypedSelector(selectOrderName);
  const email = useTypedSelector(selectOrderEmail);

  const t = useTranslations();

  return (
    <OrderFormSection
      title={t('order-form.personal_details')}
      blocked={submitLoading}
    >
      <Row>
        <TextInput
          id="order-form-name"
          label={t('order-form.name_fio')}
          value={name}
          onChange={(e) => dispatch(setName(e.target.value))}
        />
        <TextInput
          id="order-form-email"
          label="E-Mail"
          value={email}
          onChange={(e) => dispatch(setEmail(e.target.value))}
        />
      </Row>
    </OrderFormSection>
  );
};

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  ${media.tabletSmall(css`
    grid-template-columns: 1fr;
  `)}
`;

export default OrderFormPersonal;
