export const i18nNamespaces = [
  'form',
  'errors',
  'office',
  'auth',
  'layout',
  'profile',
  'order-cancel',
  'order-form',
  'order-tariff',
  'order-card',
  'review',
  'cleaning',
  'invite',
  'utils',
  'common',
  'training',
  'content',
];
