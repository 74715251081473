import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

type Props = {
  title: string;
  stepNumber?: number;
  stepCount?: number;
};

const OrderFormTitle: React.FC<Props> = ({ title, stepCount, stepNumber }) => {
  return (
    <Component>
      <Title>{title}</Title>
      {stepCount && stepCount > 1 ? (
        <Steps>
          {stepNumber} / {stepCount}
        </Steps>
      ) : null}
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  ${media.tabletSmall(css`
    margin-bottom: 20px;
  `)}
`;

const Title = styled.h1`
  font-size: 26px;
  line-height: 150%;
  font-weight: 500;
  display: block;
  margin: 0;

  ${media.tabletSmall(css`
    font-size: 22px;
  `)}
`;

const Steps = styled.span`
  color: rgb(134, 143, 155);
  font-size: 14px;
  font-weight: 700;
`;

export default OrderFormTitle;
