import React from 'react';
import { useTranslations } from 'use-intl';

import IconCheckbox from '@/components/IconCheckbox';

import { ReactComponent as HouseIcon } from './svg/house.svg';

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
};
const OrderFormHouseCheckbox: React.FC<Props> = (props) => {
  const t = useTranslations();

  return (
    <IconCheckbox
      {...props}
      icon={<HouseIcon />}
      label={t('order-form.private_house')}
      badge="x 1.2"
    />
  );
};

export default OrderFormHouseCheckbox;
