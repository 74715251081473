import React from 'react';
import styled from 'styled-components';
import { useTranslations } from 'use-intl';

import OrderFormServices from '@/modules/Order/containers/OrderFormServices';
import OrderFormDateTime from '@/modules/Order/containers/OrderFormDateTime';
import Link from '@/components/Link';
import { useTypedSelector } from '@/store/store';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';
import OrderFormSection from '@/modules/Order/components/OrderFormSection';

const OrderFormServicesAndDate = () => {
  const submitLoading = useTypedSelector(selectOrderSubmitLoading);
  const t = useTranslations();

  return (
    <OrderFormSection blocked={submitLoading}>
      <>
        <OrderFormServices />
        <OrderFormDateTime />
        <MoreLink to={'/uslugi/uborka-kvartir'}>
          {t('order-form.what_included')}
        </MoreLink>
      </>
    </OrderFormSection>
  );
};

const MoreLink = styled(Link)`
  color: rgb(18, 207, 124);
  font-weight: 500;
  text-decoration: underline;
  transition: color 0.3s linear 0s;
  margin-top: 20px;
  display: inline-block;
`;

export default OrderFormServicesAndDate;
