import React from 'react';
import { useTranslations } from 'use-intl';

import { TextArea } from '@/components/TextInput';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectOrderComment,
  selectOrderSubmitLoading,
  setOrderComment,
} from '@/modules/Order/store/first-step';
import OrderFormSection from '@/modules/Order/components/OrderFormSection';

const OrderFormComment = () => {
  const dispatch = useTypedDispatch();
  const submitLoading = useTypedSelector(selectOrderSubmitLoading);

  const orderComment = useTypedSelector(selectOrderComment);
  const onCommentChange = (value: string) => dispatch(setOrderComment(value));

  const t = useTranslations();

  return (
    <OrderFormSection blocked={submitLoading}>
      <TextArea
        label={t('order-form.comment')}
        placeholder={t('order-form.comment_placeholder')}
        value={orderComment || ''}
        onChange={(e) => {
          onCommentChange(e.target.value);
        }}
      />
    </OrderFormSection>
  );
};

export default OrderFormComment;
