import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import OrderFormSecondaryServices from '@/modules/Order/containers/OrderFormSecondaryServices';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectSecondaryServiceSubmitting } from '@/modules/Order/store/secondary-services';

const OrderFormSecondStepServices = () => {
  const t = useTranslations();

  const loading = useTypedSelector(selectSecondaryServiceSubmitting);
  return (
    <>
      <Header>
        <Title>{t('order-form.add_additional')}:</Title>
      </Header>
      <OrderFormSecondaryServices disabled={loading} />
    </>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  ${media.tablet(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const Title = styled.span`
  font-size: 22px;
  font-weight: 700;
  display: block;

  ${media.tablet(css`
    margin-bottom: 10px;
  `)}
`;

const Right = styled.div`
  a {
    color: rgb(18, 207, 124);
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  margin-left: 10px;

  ${media.tablet(css`
    margin-left: 0;
  `)}
`;

export default OrderFormSecondStepServices;
