import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import { colors } from '@/constants/theme';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectPriceWithoutDiscount } from '@/modules/Order/store';
import {
  selectSelectedSubscription,
  selectSubscriptionSubmitLoading,
  setSelectedSubscription,
} from '@/modules/Order/store/subscription';
import { selectOrderSettingsSubscriptions } from '@/modules/Order/store/common';
import { OrderSettingsSubscription } from '@/services/requests/orders';
import { media } from '@/utils/mixin';
import { useCurrency } from '@/hooks/useCurrency';

const OrderFormThirdStepSubscription = () => {
  const dispatch = useTypedDispatch();

  const price = Math.round(useTypedSelector(selectPriceWithoutDiscount));
  const selectedSubscription = useTypedSelector(selectSelectedSubscription);
  const loading = useTypedSelector(selectSubscriptionSubmitLoading);

  const orderSubscriptions = useTypedSelector(selectOrderSettingsSubscriptions);

  const onSelect = (subscription: OrderSettingsSubscription | null) => {
    dispatch(setSelectedSubscription(subscription));
  };

  const getDiscount = (): number => {
    if (!selectedSubscription) {
      return 0;
    }

    return (
      ((price * selectedSubscription.discount) / 100) *
      selectedSubscription.ordersInMonth
    );
  };

  const currency = useCurrency();

  const t = useTranslations();

  return (
    <Component>
      <Title>
        {t('order-form.subscription_title_1')}{' '}
        <strong>- {t('order-form.subscription_title_2')}</strong>
      </Title>
      <Text>{t('order-form.subscription_next')}</Text>
      <Grid>
        <Item
          selected={selectedSubscription === null}
          onClick={() => onSelect(null)}
          disabled={loading}
        >
          <ItemLabel>{t('order-form.single_order')}</ItemLabel>
          <ItemPrice>
            {price} {currency}
          </ItemPrice>
        </Item>

        {orderSubscriptions.map((item) => (
          <Item
            selected={selectedSubscription?.id === item.id}
            onClick={() => (loading ? () => {} : onSelect(item))}
            disabled={loading}
          >
            <ItemPercent>-{item.discount}%</ItemPercent>
            <ItemLabel>{item.name}</ItemLabel>
            <ItemPrice>
              {Math.round(price - (price * item.discount) / 100)} {currency}
            </ItemPrice>
          </Item>
        ))}
      </Grid>
      <Summary>
        {t('order-form.you_save')}: {getDiscount().toFixed(2)} {currency}/
        {t('utils.month_short')}
      </Summary>
    </Component>
  );
};

const Component = styled.div`
  padding: 40px 20px;
  background-color: rgb(244, 244, 244);
  border-radius: 5px;
  margin: 25px 0;

  ${media.tabletSmall(css`
    padding: 20px;
    margin: 20px 0;
  `)}
`;

const Title = styled.span`
  display: block;
  text-align: center;
  font-size: 28px;
  line-height: 150%;
  font-weight: 700;

  ${media.tabletSmall(css`
    font-size: 20px;
  `)}
  strong {
    color: ${colors.green};
  }
`;

const Text = styled.span`
  display: block;
  text-align: center;
  margin-top: 15px;
  font-weight: 500;

  ${media.tabletSmall(css`
    font-size: 14px;
  `)}
`;

const Summary = styled.span`
  display: block;
  text-align: center;
  color: rgb(61, 61, 61);
  font-size: 23px;
  font-weight: 500;
  margin-top: 30px;

  ${media.tabletSmall(css`
    font-size: 18px;
    margin-top: 20px;
  `)}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 35px auto;
  width: 620px;

  ${media.tablet(css`
    width: auto;
  `)}

  ${media.tabletSmall(css`
    grid-template-columns: 1fr 1fr;
    width: auto;
    margin: 25px auto;
  `)}
`;

const Item = styled.div<{ selected?: boolean; disabled: boolean }>`
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(218, 218, 218);
  padding: 36px 5px 20px;
  border-radius: 10px;
  position: relative;
  display: block;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  color: rgb(61, 61, 61);
  white-space: nowrap;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ${(props) =>
    props.selected
      ? css`
          background: ${colors.green};
          border-color: ${colors.green};
          color: #fff;
        `
      : null}
`;

const ItemPercent = styled.span`
  display: block;
  border-radius: 0px 0px 3px 3px;
  font-size: 15px;
  background-color: rgb(255, 225, 108);
  font-weight: 500;
  font-style: normal;
  color: rgb(61, 61, 61);
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 4px;
`;

const ItemLabel = styled.span`
  display: block;
  text-align: center;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
`;

const ItemPrice = styled.span`
  display: block;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
`;

export default OrderFormThirdStepSubscription;
