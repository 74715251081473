import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectOrderMode, selectStep } from '@/modules/Order/store/common';
import OrderFormSidebar from '@/modules/Order/containers/OrderFormSidebar/OrderFormSidebar';
import OrderFormTitle from '@/modules/Order/components/OrderFormTitle';
import OrderFormFirstStep from '@/modules/Order/modules/OrderCreate/containers/OrderFormFirstStep';
import OrderFormSecondStep from '@/modules/Order/modules/OrderCreate/containers/OrderFormSecondStep/OrderFormSecondStep';
import OrderFormThirdStep from '@/modules/Order/modules/OrderCreate/containers/OrderFormThirdStep/OrderFormThirdStep';
import { OrderFormMode } from '@/enums/order-form-mode';
import { selectUserProfile } from '@/store/reducers/auth';
import { personalActions } from '@/modules/Order/store/personal';
import { media } from '@/utils/mixin';
import OrderFormMobileSummary from '@/modules/Order/containers/OrderFormSummary/OrderFormMobileSummary';

type Props = {};
const OrderCreateMain: React.FC<Props> = () => {
  const dispatch = useTypedDispatch();
  const step = useTypedSelector(selectStep);
  const mode = useTypedSelector(selectOrderMode);

  const t = useTranslations();

  const title =
    mode === OrderFormMode.Windows
      ? t('order-form.order_create_windows_title')
      : t('order-form.order_create_rooms_title');
  const steps = OrderFormMode.Windows ? 1 : 3;

  const userProfile = useTypedSelector(selectUserProfile);

  useEffect(() => {
    if (userProfile) {
      dispatch(
        personalActions.setPersonalData({
          name: userProfile.name,
          email: userProfile.email,
        })
      );
    }
  }, [userProfile]);

  return (
    <Wrapper>
      <Main>
        {step === 1 ? (
          <OrderFormTitle title={title} stepNumber={step} stepCount={steps} />
        ) : null}
        {step === 1 ? <OrderFormFirstStep /> : null}
        {step === 2 ? <OrderFormSecondStep /> : null}
        {step === 3 ? <OrderFormThirdStep /> : null}
      </Main>
      <OrderFormSidebar extended={step === 1} />
      <OrderFormMobileSummary />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 66fr 34fr;
  gap: 40px;

  ${media.tabletSmallOnly(css`
    gap: 20px;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const Main = styled.div`
  ${media.tabletSmallOnly(css`
    max-width: calc(100vw - 370px);
  `)}
`;

export default OrderCreateMain;
