import React from 'react';
import styled from 'styled-components';
import Router from 'next/router';
import { useTranslations } from 'use-intl';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectOrderComment,
  selectOrderSubmitDisabled,
  selectOrderSubmitLoading,
  setSubmitLoading,
} from '@/modules/Order/store/first-step';
import {
  selectOrderId,
  selectOrderSettingsMinTotal,
  selectUsedBalance,
  setErrorMessage,
} from '@/modules/Order/store/common';
import OrderFormSubmit from '@/modules/Order/components/OrderFormSubmit';
import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import { updateOrder, UpdateOrderRequest } from '@/services/requests/orders';
import { dateToString } from '@/utils/common';
import {
  selectSelectedDate,
  selectSelectedTime,
} from '@/modules/Order/store/datetime';
import {
  selectAddressCityId,
  selectAddressEntrance,
  selectAddressFlat,
  selectAddressFloor,
  selectAddressFrame,
  selectAddressHouse,
  selectAddressIntercomCode,
  selectAddressSelectedId,
  selectAddressStreet,
  selectAddressType,
} from '@/modules/Order/store/address';
import {
  selectFirstServiceValue,
  selectSecondServiceValue,
} from '@/modules/Order/store/services';
import {
  selectDeliveryKeysAddress,
  selectOrderSecondaryServicesSelected,
} from '@/modules/Order/store/secondary-services';
import { selectPriceWithoutDiscount } from '@/modules/Order/store';
import { scrollToElement } from '@/utils/scroll';
import { selectPromoValue } from '@/modules/Order/store/promo';
import { useCurrency } from '@/hooks/useCurrency';

const OrderFormFirstStep = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslations();

  const orderId = useTypedSelector(selectOrderId);

  const priceWithoutDiscount = useTypedSelector(selectPriceWithoutDiscount);
  const minTotal = useTypedSelector(selectOrderSettingsMinTotal);

  const submitLoading = useTypedSelector(selectOrderSubmitLoading);
  const submitDisabled = useTypedSelector(selectOrderSubmitDisabled);

  const selectedDate = useTypedSelector(selectSelectedDate);
  const selectedTime = useTypedSelector(selectSelectedTime);
  const comment = useTypedSelector(selectOrderComment);

  const cityId = useTypedSelector(selectAddressCityId);
  const street = useTypedSelector(selectAddressStreet);
  const house = useTypedSelector(selectAddressHouse);
  const frame = useTypedSelector(selectAddressFrame);
  const entrance = useTypedSelector(selectAddressEntrance);
  const floor = useTypedSelector(selectAddressFloor);
  const flat = useTypedSelector(selectAddressFlat);
  const intercomCode = useTypedSelector(selectAddressIntercomCode);
  const firstServiceValue = useTypedSelector(selectFirstServiceValue);
  const secondServiceValue = useTypedSelector(selectSecondServiceValue);
  const promo = useTypedSelector(selectPromoValue);
  const type = useTypedSelector(selectAddressType);
  const usedBalance = useTypedSelector(selectUsedBalance);

  const selectedServices = useTypedSelector(
    selectOrderSecondaryServicesSelected
  );
  const keysDeliveryAddress = useTypedSelector(selectDeliveryKeysAddress);

  const addressCityId = useTypedSelector(selectAddressSelectedId);

  const currency = useCurrency();

  const getOrderUpdateRequest = (): UpdateOrderRequest | null => {
    if (!selectedTime || !selectedDate) return null;

    return {
      date: dateToString(selectedDate),
      time: selectedTime,
      services: [
        firstServiceValue?.quantity || 0,
        secondServiceValue?.quantity || 0,
      ],
      addressId: addressCityId,
      addressCustom: {
        type,
        city: cityId,
        street,
        house,
        frame,
        entrance: typeof entrance === 'undefined' ? null : entrance,
        apartment: typeof flat === 'undefined' ? null : flat,
        floor: typeof floor === 'undefined' ? null : floor,
        intercomCode,
      },
      secondaryServices:
        selectedServices
          .filter((item) => item.value !== null)
          .map((item) => {
            return {
              id: item.id,
              value: Number(item.value),
            };
          }) || [],
      keysDeliveryAddress,
      promo: promo?.code || null,
      comment,
      usedBalance,
    };
  };

  const validate = (): string | null => {
    if (!selectedDate) {
      if (window.innerWidth <= 767) {
        scrollToElement('order-form-date');
      }
      return t('order-form.error_date');
    }

    if (!selectedTime) {
      if (window.innerWidth <= 767) {
        scrollToElement('order-form-time');
      }
      return t('order-form.error_time');
    }

    if (!addressCityId) {
      if (String(street).trim().length === 0) {
        if (window.innerWidth <= 767) {
          scrollToElement('order-form-street');
        }
        return t('order-form.error_street');
      }

      if (String(house).trim().length === 0) {
        if (window.innerWidth <= 767) {
          scrollToElement('order-form-house');
        }
        return t('order-form.error_house');
      }

      if (type === 'APARTMENT' && !floor) {
        return t('order-form.error_floor');
      }
    }

    if (minTotal && priceWithoutDiscount < minTotal) {
      return t('order-form.min_sum_order') + ': ' + minTotal + ' ' + currency;
    }

    return null;
  };

  const onSubmit = async () => {
    if (!orderId) return;

    const errorMessage = validate();
    if (errorMessage) {
      dispatch(setErrorMessage(errorMessage));
      return;
    }

    dispatch(setErrorMessage(null));
    dispatch(setSubmitLoading(true));

    try {
      const request = getOrderUpdateRequest();
      if (request) {
        await updateOrder(orderId, request);
        await Router.replace('/profile/orders');
      }
    } catch (e) {
    } finally {
      dispatch(setSubmitLoading(false));
    }
  };

  return (
    <SubmitSection blocked={false} noLine={true}>
      <OrderFormSubmit
        isContainer={false}
        submitLabel={t('order-form.save_changes')}
        onSubmit={onSubmit}
        submitLoading={submitLoading}
        submitDisabled={submitDisabled}
      />
    </SubmitSection>
  );
};

const SubmitSection = styled(OrderFormSection)`
  display: flex;
  align-items: center;
`;

export default OrderFormFirstStep;
