import React from 'react';
import { useTranslations } from 'use-intl';

import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import OrderFormSecondaryServices from '@/modules/Order/containers/OrderFormSecondaryServices';
import { useTypedSelector } from '@/store/store';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';

const OrderEditSecondaryServices = () => {
  const loading = useTypedSelector(selectOrderSubmitLoading);

  const t = useTranslations();

  return (
    <OrderFormSection
      title={t('order-form.additional_title')}
      blocked={loading}
    >
      <OrderFormSecondaryServices loadServices={false} />
    </OrderFormSection>
  );
};

export default OrderEditSecondaryServices;
