import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import OrderFormSidebarBlock from '@/modules/Order/containers/OrderFormSidebar/components/OrderFormSidebarBlock';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import Button from '@/components/Button';
import { promoActions, selectPromoValue } from '@/modules/Order/store/promo';
import { PromoCodeType } from '@/enums/promo-code-type';
import { PromoCodeDiscountType } from '@/enums/promo-code-discount-type';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';
import { media } from '@/utils/mixin';
import { useCurrency } from '@/hooks/useCurrency';

const OrderFormPromoSelected: React.FC = () => {
  const promoValue = useTypedSelector(selectPromoValue);
  const dispatch = useTypedDispatch();

  const submitLoading = useTypedSelector(selectOrderSubmitLoading);

  const onCancel: MouseEventHandler = (e) => {
    e.preventDefault();
    dispatch(promoActions.setPromoValue(null));
  };

  const currency = useCurrency();

  const t = useTranslations();

  return (
    <OrderFormSidebarBlock>
      <Row>
        <Cell>
          <Param>
            {promoValue?.type === PromoCodeType.Promo
              ? t('order-form.promocode')
              : t('order-form.referral_code')}
          </Param>
          <Value>{promoValue?.code}</Value>
        </Cell>
        <Cell>
          <Param>{t('order-form.discount')}:</Param>
          <Value>
            {promoValue?.discount}
            {promoValue?.discountType === PromoCodeDiscountType.Percent
              ? '%'
              : ' ' + currency}
          </Value>
        </Cell>
      </Row>
      <Action>
        <Button
          colorVariant="red"
          modeVariant="text"
          onClick={onCancel}
          disabled={submitLoading}
        >
          {t('order-form.promocode_cancel_promocode')}
        </Button>
      </Action>
    </OrderFormSidebarBlock>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const Cell = styled.div`
  padding: 0 30px;
  text-align: center;

  &:first-child {
    border-right: 1px solid rgb(218, 218, 218);
  }

  ${media.tabletSmall(css`
    padding: 0 25px;
  `)}
`;

const Param = styled.span`
  color: rgb(134, 143, 155);
  margin-bottom: 5px;
  display: block;
  white-space: nowrap;
`;

const Value = styled.span`
  font-size: 20px;
  color: rgb(18, 207, 124);
  font-weight: 700;
  display: block;
  white-space: nowrap;

  ${media.tabletSmall(css`
    font-size: 16px;
  `)}
`;

const Action = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  margin-bottom: -10px;
`;

export default OrderFormPromoSelected;
