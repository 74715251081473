import { i18nNamespaces } from '@/i18n/i18n.constants';

export const getCurrentLocale = () => process.env.NEXT_PUBLIC_LANGUAGE || 'ru';

export const loadLocale = async (locale: string) => {
  const messages: any = {};
  for (let i = 0; i < i18nNamespaces.length; i++) {
    messages[i18nNamespaces[i]] = await import(
      `./locales/${locale as string}/${i18nNamespaces[i]}.json`
    );
  }

  return messages;
};
