import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import { media } from '@/utils/mixin';

import cleanerImg from '../images/cleaner.webp';

const OrderFormThirdStepCleaner = () => {
  const t = useTranslations();

  return (
    <Component>
      <Left>
        <Title>
          {t('order-form.subscription_worker')}{' '}
          <strong>{t('order-form.subscription_worker_postfix')}</strong>
        </Title>
        <Text>{t('order-form.subscription_worker_text')}</Text>
      </Left>
      <Cleaner>
        <img src={cleanerImg} />
        <CleanerRating>4,8</CleanerRating>
      </Cleaner>
    </Component>
  );
};

const Component = styled.div`
  padding-bottom: 25px;
  border-bottom: 1px dashed rgb(218, 218, 218);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${media.tabletSmall(css`
    font-size: 14px;
    flex-direction: column-reverse;
    align-items: flex-start;
    border-bottom: 0 none;
    padding-bottom: 0;
  `)}
`;

const Left = styled.div`
  padding-right: 40px;

  ${media.tabletSmall(css`
    padding-right: 0;
  `)}
`;

const Title = styled.span`
  font-size: 28px;
  line-height: 130%;
  font-weight: 700;
  display: block;

  ${media.tabletSmall(css`
    font-size: 18px;
    line-height: 150%;
  `)}
  strong {
    color: rgb(18, 207, 124);
  }
`;

const Text = styled.p`
  font-size: 22px;
  line-height: 24px;
  color: rgb(153, 153, 153);
  margin-top: 15px;
  display: block;

  ${media.tabletSmall(css`
    font-size: 18px;
    margin-top: 10px;
  `)}
`;

const Cleaner = styled.div`
  width: 135px;
  height: 135px;
  min-height: 135px;
  min-width: 135px;
  border-radius: 100%;
  border: 2px solid rgb(18, 207, 124);
  position: relative;
  padding: 5px;

  ${media.tabletSmall(css`
    margin: 0 0 20px;
    display: none;
  `)}
  img {
    border-radius: 100%;
  }
`;

const CleanerRating = styled.span`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  color: rgb(255, 255, 255);
  background-color: rgb(18, 207, 124);
  border-radius: 13px;
  border: 3px solid rgb(255, 255, 255);
  padding: 0px 8px;
  font-weight: 500;
  font-size: 15px;
`;

export default OrderFormThirdStepCleaner;
