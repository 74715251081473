import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import PrimaryServiceSelect from '@/components/PrimaryServiceSelect';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectFirstServiceOptions,
  selectFirstServiceValue,
  selectSecondServiceOptions,
  selectSecondServiceValue,
  setFirstServiceQuantity,
  setSecondServiceQuantity,
} from '@/modules/Order/store/services';
import { selectOrderMode } from '@/modules/Order/store/common';
import { OrderFormMode } from '@/enums/order-form-mode';
import { media } from '@/utils/mixin';

const OrderFormServices = () => {
  const dispatch = useTypedDispatch();

  const mode = useTypedSelector(selectOrderMode);

  const firstServiceValue = useTypedSelector(selectFirstServiceValue);
  const firstServiceOptions = useTypedSelector(selectFirstServiceOptions);

  const secondServiceValue = useTypedSelector(selectSecondServiceValue);
  const secondServiceOptions = useTypedSelector(selectSecondServiceOptions);

  const onFirstServiceChange = (value: number | null) => {
    const found = firstServiceOptions.find((item) => item.id === value);
    if (found) {
      dispatch(setFirstServiceQuantity(found.quantity));
    }
  };

  const onSecondServiceChange = (value: number | null) => {
    const found = secondServiceOptions.find((item) => item.id === value);
    if (found) {
      dispatch(setSecondServiceQuantity(found.quantity));
    }
  };

  const firstServiceSelectOptions = firstServiceOptions.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  let secondServiceSelectOptions: Array<{
    value: number | null;
    label: string;
  }> = secondServiceOptions.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const t = useTranslations();

  const firstTitle =
    mode === OrderFormMode.Windows
      ? t('order-form.select_windows_count')
      : t('order-form.select_rooms_count');

  const secondTitle =
    mode === OrderFormMode.Windows
      ? t('order-form.select_balconies_count')
      : t('order-form.select_bathrooms_count');

  return (
    <Component>
      <PrimaryServiceSelect
        label={firstTitle}
        options={firstServiceSelectOptions}
        onChange={onFirstServiceChange}
        value={firstServiceValue?.id || null}
      />

      <PrimaryServiceSelect
        label={secondTitle}
        options={secondServiceSelectOptions}
        onChange={onSecondServiceChange}
        value={secondServiceValue?.id || null}
      />
    </Component>
  );
};

const Component = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  ${media.tabletSmall(css`
    grid-template-columns: 1fr;
    gap: 20px;
  `)}
`;

export default OrderFormServices;
