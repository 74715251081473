import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { addDays } from 'date-fns';
import { useTranslations } from 'use-intl';

import { Nullable } from '@tager/web-core';

import TimeSelect from '@/components/TimeSelect';
import Calendar from '@/components/Calendar';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  dateTimeActions,
  selectBusyDates,
  selectCoefficients,
  selectSelectedDate,
  selectSelectedTime,
} from '@/modules/Order/store/datetime';
import { dateToString } from '@/utils/common';
import { generateTimeOptions } from '@/modules/Order/utils';
import { media } from '@/utils/mixin';

type TimeOption = { value: string; disabled?: boolean };

const OrderFormDateTime = () => {
  const dispatch = useTypedDispatch();

  const [forceTimeOpened, setForceTimeOpened] = useState<boolean>(false);

  const disabledDatesData = useTypedSelector(selectBusyDates);
  const coefficients = useTypedSelector(selectCoefficients);
  const selectedDate = useTypedSelector(selectSelectedDate);
  const selectedTime = useTypedSelector(selectSelectedTime);

  const selectedDateString = selectedDate ? dateToString(selectedDate) : null;

  const forceOpenTimeSelect = () => {
    setForceTimeOpened(true);
    setTimeout(() => {
      setForceTimeOpened(false);
    }, 100);
  };

  useEffect(() => {
    if (!selectedDate) return;

    if (!selectedTime) {
      return forceOpenTimeSelect();
    }

    const timeOptions = getTimeOptions();
    if (!timeOptions.map((item) => item.value).includes(selectedTime)) {
      dispatch(dateTimeActions.setSelectedTime(null));
      forceOpenTimeSelect();
    }
  }, [selectedDate]);

  const getTimeOptions = (): Array<TimeOption> => {
    let result: Array<TimeOption> = [];

    const disabledTimeSlots =
      disabledDatesData.find((item) => item.date === selectedDateString)
        ?.times || [];

    generateTimeOptions().forEach((label) => {
      if (disabledTimeSlots.includes(label)) {
        return;
      }

      result.push({
        value: label,
        disabled: false,
      });
    });

    return result;
  };

  const disabledDates = disabledDatesData
    .filter(
      (item) =>
        item.times.length === 0 || item.times.length === getTimeOptions().length
    )
    .map((item) => item.date);

  const onDateSelect = (value: Date) => {
    dispatch(dateTimeActions.setSelectedDate(value));
  };

  const onTimeSelect = (value: Nullable<TimeOption>) => {
    dispatch(dateTimeActions.setSelectedTime(value ? value.value : null));
  };

  const t = useTranslations();

  return (
    <Component>
      <Calendar
        id="order-form-date"
        placeholderText={t('order-form.date-placeholder')}
        value={selectedDate as any}
        onChange={onDateSelect}
        minDate={addDays(new Date(), 1)}
        isHideRaisingCoefficient={true}
        disabledDates={disabledDates}
        dayCoefficients={coefficients}
      />
      <TimeSelect
        id="order-form-time"
        value={selectedTime}
        onChange={onTimeSelect}
        options={getTimeOptions()}
        label={t('order-form.time-placeholder')}
        forceOpen={forceTimeOpened}
      />
    </Component>
  );
};

const Component = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 20px;
  position: relative;

  ${media.tabletSmall(css`
    grid-template-columns: 1fr;
    gap: 10px;
  `)}
`;

export default OrderFormDateTime;
