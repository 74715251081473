import React from 'react';

import { Nullable, RequestError } from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import { Address, ThemeColor } from '@/typings/common';
import { colors } from '@/constants/theme';
import { PrimaryOrderServiceType } from '@/typings/model';
import { TFunction } from '@/i18n/i18n.types';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function getParamAsString(
  param: Array<string> | string | undefined
): string {
  return Array.isArray(param) ? param[0] : param ?? '';
}

export function getPhoneNumber(formattedNumber: string): string {
  return formattedNumber
    .split('')
    .filter((character) => /[\d+]/.test(character))
    .join('');
}

export function getColorByVariant(variant: ThemeColor): string {
  switch (variant) {
    case 'blue':
      return colors.blue;
    case 'green':
      return colors.green;
    case 'yellow':
      return colors.yellow;
  }
}

export function getWebsiteOrigin(): string {
  return typeof window !== 'undefined' ? window.location.origin : '/';
}

export function getPrimaryOrderServiceBySystemName(
  primaryOrderServiceList: Array<PrimaryOrderServiceType>,
  systemName: string
): Nullable<PrimaryOrderServiceType> {
  return (
    primaryOrderServiceList.find(
      (primaryOrderService) => primaryOrderService.systemName === systemName
    ) ?? null
  );
}

export function numberTo2(number: number | string): string {
  return (number < 10 ? '0' + number : number).toString();
}

export function dateToString(date: Date): string {
  return [
    date.getFullYear(),
    numberTo2(date.getMonth() + 1),
    numberTo2(date.getDate()),
  ].join('-');
}

export function stringToDate(date: string): Date {
  return new Date(Date.parse(date));
}

export function dateToHumanString(date: Date): string {
  return (
    numberTo2(date.getDate()) +
    '.' +
    numberTo2(date.getMonth() + 1) +
    '.' +
    date.getFullYear()
  );
}

export function workTimeHumanString(minutes: number, t: TFunction): string {
  if (minutes < 60) {
    return minutes + ' ' + t('utils.min_short');
  }

  let hours = minutes / 60;
  hours = Math.round(hours * 100) / 100;

  return hours + ' ' + t('utils.hour_short');
}

export function getAddressHumanString(address: Address, t: TFunction): string {
  let result = `${t('utils.city_short')} ${address.city}, `;
  if (address.street) {
    result += address.street;
  }

  if (address.house) {
    result +=
      ` ${t('utils.house_short')} ` +
      address.house +
      (address.frame ? ` ${t('utils.korpus')} ` + address.frame : '');
  }

  if (address.entrance) {
    result +=
      `, ${t('utils.entrance')} ` +
      address.entrance +
      (address.intercomCode
        ? ` (${t('utils.code')} - ` + address.intercomCode + ')'
        : '');
  }

  if (address.flat) {
    result += `, ${t('utils.apartment_short')} ` + address.flat;
  }

  if (address.floor) {
    result += ` (${t('utils.floor')} ` + address.floor + ')';
  }

  return result;
}
